import type { FC } from 'react'

import { useGetParagraphQuery } from '@redux/features/homePage/api'

import { BackgroundImage, CardWrapper, Content, Icon, Text, Title } from './styles'

type Props = {
  itemId: number
}

export const Card: FC<Props> = ({ itemId }) => {
  const { data } = useGetParagraphQuery(itemId)

  if (!data) return

  return (
    <CardWrapper>
      {data.image?.uri.url && <BackgroundImage alt="background" fill quality={50} src={data.image?.uri.url} />}
      <Icon $iconUrl={data?.icon && data.icon.uri.url} />
      <Content>
        <Title>{data.title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: data.description || '' }} size="small" />
      </Content>
    </CardWrapper>
  )
}
